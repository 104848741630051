import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

// Interfaces
import { DateTime } from 'luxon';
import { Table } from 'primeng/table';
import { FilterMetadata } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import {
  CellTemplates,
  DEFAULT_RECORD_COUNTS,
  DatatableColumnV3,
  DateTypes,
  SettingDropdownItems,
  TableActionTypes,
  MATCH_MODE_BY_OPERATOR,
  ItemType,
  MetadataIdentifiers,
} from '../../types';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../../constants';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'rw-table-desktop',
  templateUrl: './table-desktop.component.html',
  styleUrls: ['./table-desktop.component.scss'],
})
export class TableDesktopComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('datatable') datatable: Table;

  @ViewChild('paginator') paginator: Paginator;

  @Input() columns: DatatableColumnV3[];

  @Input() scrollable: boolean = false;

  @Input() scrollHeight: string = '400px'; /** In px **/

  @Input() selectedColumns: DatatableColumnV3[];

  @Input() values: any[];

  @Input() leftFrozenWidth: number;

  @Input() rightFrozenWidth: number;

  @Input() totalRecords = 0;

  @Input() selectedRows: any[];

  @Input() showCheckboxes: boolean;

  @Input() resizeColumn: boolean;

  @Input() loading = false;

  @Input() trackByIdentifier = 'id';

  @Input() showCurrentPageReport = false;

  @Input() isMobileResolution = false;

  @Input() newDataHighlightColumnName: string; // Date String

  @Input() newDataHighlightMaxTime: number; // Time in milliseconds

  @Output() loadValues = new EventEmitter<any>();

  @Output() columnsUpdated = new EventEmitter<DatatableColumnV3[]>();

  @Output() selectionChange = new EventEmitter<any[]>();

  @Output() paginatorInitialized = new EventEmitter<void>();

  @Output() pageChanged = new EventEmitter<void>();

  private pollingSubscription: Subscription;

  filters = {};

  lng: string;

  langCss: string;

  statusMap = {
    Completed: 'Completed',
    Draft: 'In Progress',
  };

  tableActionTypes = TableActionTypes;

  itemTypes = ItemType;

  showColumnsSelection = false;

  @Input() recordCounts = DEFAULT_RECORD_COUNTS;

  dateTypes = DateTypes;

  numberOfResults: number;

  cellTemplates = CellTemplates;

  tableFilters = {};

  dateRanges = {};

  autoCompleteField = 'name';

  autoCompleteSearches = {};

  searchValues = {};

  originalTableColumns: DatatableColumnV3[] = [];

  customizeColumnOptions: DatatableColumnV3[] = [];

  rowItemChildren: SettingDropdownItems[] = [];

  metadataIdentifiers = MetadataIdentifiers;

  constructor(
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit(): void {
    [this.numberOfResults] = this.recordCounts;

    this.initTableFilterObj();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns && changes.columns.currentValue) {
      this.originalTableColumns = changes.columns.currentValue;
      this.customizeColumnOptions = this.originalTableColumns.filter(
        (column) => column.actionType !== 'customize',
      );
    }
  }

  initTableFilterObj(): void {
    this.columns.forEach((col) => {
      this.tableFilters[col.prop] = '';
    });
  }

  handleOnPageChanged(event): void {
    this.handleLazyLoading(event, true);
    this.pageChanged.emit();
  }

  handleLazyLoading(e, onlyPageChange = false): void {
    // if (e.filters) this.setFilters(e.filters);
    this.getData(e, onlyPageChange);
  }

  tableFilterApplied(event): void {
    this.tableFilters = event.tableFilters;
    this.dateRanges = event.dateRanges;
    this.autoCompleteSearches = event.autoCompleteSearches;
  }

  resetDateFilter(col: DatatableColumnV3): void {
    this.dateRanges[col.prop] = '';
  }

  private resetColumnFilter(col: DatatableColumnV3): void {
    this.tableFilters[col.prop] = null;
  }

  private isNumericFilter(obj) {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
  }

  getData(e, onlyPageChange = false): void {
    const event = e;
    Object.keys(this.tableFilters).forEach((key) => {
      if (this.datatable && this.datatable.filters[key]) {
        const filterValue = this.datatable.filters[key][0].value;
        if (this.isNumericFilter(filterValue)) {
          const { operator } = filterValue;
          this.datatable.filters[key][0].operator = operator;
          this.datatable.filters[key][0].matchMode =
            MATCH_MODE_BY_OPERATOR[operator];
        }
        this.datatable.filters[key][0].value = this.tableFilters[key];
      } else if (this.datatable && !this.datatable.filters[key]) {
        this.datatable.filters[key] = [];
        this.datatable.filters[key][0] = { value: this.tableFilters[key] };
      }
    });

    if (this.datatable) {
      this.columns.forEach((col: DatatableColumnV3): void => {
        if (
          col.filter &&
          (col.cellTemplate === CellTemplates.Numeric ||
            col.cellTemplate === CellTemplates.NumericV2) &&
          this.datatable.filters[col.prop]?.[0]?.value &&
          col.operator
        ) {
          this.datatable.filters[col.prop][0].operator = col.operator;
          this.datatable.filters[col.prop][0].matchMode =
            MATCH_MODE_BY_OPERATOR[col.operator];
        }
      });
      event.filters = this.datatable.filters;
    }

    this.loadValues.emit({
      ...event,
      dateRanges: this.dateRanges,
      autoCompleteSearches: this.autoCompleteSearches,
      onlyPageChange,
    });
  }

  clearAllFilters(table: Table): void {
    table.clear();
  }

  removeFilter(key: string): void {
    const { filters } = this.datatable;
    (filters[key] as FilterMetadata[])[0].value = null;
    if (this.autoCompleteSearches[key]) this.autoCompleteSearches[key] = [];
    if (this.dateRanges[key]) this.dateRanges[key] = '';
    this.tableFilters[key] = filters[key][0].value;
    const getObj = {
      filters,
      first: this.datatable.first,
      rows: this.datatable.rows,
      sortField: this.datatable.sortField,
      sortOrder: this.datatable.sortOrder,
    };
    this.getData(getObj);
  }

  toggleColumnsSelection(): void {
    this.showColumnsSelection = !this.showColumnsSelection;
  }

  updateSelectedColumns(columns = []): void {
    this.columnsUpdated.emit(columns);
  }

  onSelectionChange(event: any[]): void {
    this.selectedRows = event;
    this.selectionChange.emit(this.selectedRows);
  }

  isRowSelected(rowData: any): boolean {
    return this.selectedRows.includes(rowData);
  }

  updateNumberOfRows(): void {
    this.datatable.rows = this.numberOfResults;
    const getObj = {
      filters: this.datatable.filters,
      first: this.datatable.first,
      rows: this.datatable.rows,
      sortField: this.datatable.sortField,
      sortOrder: this.datatable.sortOrder,
    };
    if (this.showCurrentPageReport) {
      this.updatePaginator();
      this.paginator.changePage(0);
    }
    // this.updatePaginator();
    this.getData(getObj, true);
  }

  updatePaginator() {
    if (this.paginator) {
      if (this.numberOfResults >= this.totalRecords) {
        this.paginator.first = 0;
      }
    }
  }

  filtersUpdated(event): void {
    this.tableFilters = event.filters;
    this.dateRanges = event.dateRanges;
    this.autoCompleteSearches = event.autoCompleteSearches;
    Object.keys(this.dateRanges).forEach((key) => {
      if (this.dateRanges[key]) {
        if (this.dateRanges[key].length === 2) {
          this.tableFilters[key] = [
            DateTime.fromJSDate(this.dateRanges[key][0])
              .startOf('day')
              .toJSDate(),
            DateTime.fromJSDate(this.dateRanges[key][1])
              .endOf('day')
              .toJSDate(),
          ];
        } else {
          this.tableFilters[key] = [
            DateTime.fromJSDate(this.dateRanges[key]).startOf('day').toJSDate(),
            DateTime.fromJSDate(this.dateRanges[key]).endOf('day').toJSDate(),
          ];
        }
      } else {
        this.tableFilters[key] = '';
      }
    });
    const getObj = {
      first: this.datatable.first,
      rows: this.datatable.rows,
      sortField: this.datatable.sortField,
      sortOrder: this.datatable.sortOrder,
    };
    this.getData(getObj);
  }

  actionItemClick(event, menu, item: SettingDropdownItems, data): void {
    this.rowItemChildren = item.children ? item.children(data) : [];
    if (this.rowItemChildren.length) {
      return menu.toggle(event);
    }
    if (item.handler) {
      return item.handler(data);
    }
  }

  selectedColumnsChanged(event): void {
    const selectedColumns: DatatableColumnV3[] = [];
    this.columns.forEach((column) => {
      const index = event.value.findIndex((item) => item.prop === column.prop);
      if (index !== -1) {
        column.selected = true;
        selectedColumns.push(column);
      } else {
        column.selected = false;
      }
    });
    this.selectedColumns = selectedColumns;
    this.updateSelectedColumns(this.selectedColumns);
  }

  rowItemClicked(appMenu, item, data): void {
    item.handler(data);
    appMenu.toggle(false);
  }

  clearAllFilterTags(): void {
    const getObj = {
      first: this.datatable.first,
      rows: this.datatable.rows,
      sortField: this.datatable.sortField,
      sortOrder: this.datatable.sortOrder,
    };
    this.dateRanges = {};
    this.autoCompleteSearches = {};
    this.clearTableFilter();
    this.getData(getObj);
  }

  clearTableFilter(): void {
    Object.keys(this.tableFilters).forEach((key) => {
      this.tableFilters[key] = null;
    });
  }

  trackByFunc(index: number, data: any) {
    return data[this.trackByIdentifier] || index;
  }

  ngAfterViewInit(): void {
    this.startPollingForPaginator();
    this.initializeFixedHeader();
  }

  private initializeFixedHeader(): void {
    if (!this.scrollable || this.isMobileResolution) {
      return;
    }
    this.datatable.scrollable = this.scrollable;
    this.datatable.scrollHeight = this.scrollHeight;
  }

  private startPollingForPaginator(): void {
    this.pollingSubscription = interval(500)
      .pipe(takeWhile(() => !this.paginator, true))
      .subscribe(() => {
        if (this.paginator) {
          this.paginatorInitialized.emit();
          this.stopPollingForPaginator();
        }
      });
  }

  private stopPollingForPaginator(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }
  ngOnDestroy(): void {
    this.stopPollingForPaginator();
  }
}
