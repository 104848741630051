export const environment = {
  name: 'stg-test',
  production: true,
  enablePendo: false,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://test.stg.mims.rewaatech.com',
  webSocketUrl:
    'wss://f6h417bvd8.execute-api.ap-southeast-1.amazonaws.com/stg-test',
  fullViewClientId: '',
  sallaAppClientId: '9cbc30f8-1f5f-4bbb-9eea-ea98a1624ace',
  zidAppClientId: '440',
  bonatAppClientId: 'q9lbrf2oaogk0ujokrcqk2vpm',
  bonatRedirectUrl: 'https://stg-api.bonat.io/rewaa/install',
  userPoolId: 'ap-southeast-1_1u80KfM49',
  clientId: '2h1pk6jtdinnoj8aena53jjemk',
  bonatServiceToken: '',
  cognitoAuthUrl: 'https://rewaa.auth.ap-south-1.amazoncognito.com/login',
  s3InventoryBucket:
    'https://s3.amazonaws.com/stg-test-mims-service-any-item-image/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: false,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket:
    'https://s3.ap-south-1.amazonaws.com/test.singapore.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 250, others: 300 },
  },
  enableUserGuiding: false,
  enableUserPilot: false,
  launchDarklyClientSideId: '63566d3ef1d7751181713514',
  customFieldApiPath: '/api/custom-field-service',
  enableAutomationTestFF: true,
  automationTestFFAPIKey: '260b4c17-e0de-4323-927e-618109cb518e',
  mockLaunchDarkly: true,
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  intercomAppId: 'sg0ry903',
  enableFirestore: true,
  allowAliasedEmails: false,
  reportDataLimit: 10000,
  reportingApiUrl:
    'https://reporting.test-stg.mims.rewaatech.com/cubejs-api/v1',
  appVersion: require('../../package.json').version + '-stg-test',
  nearpayEnvironment: 'sandbox',
};
