import {
  Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren, AfterViewInit, Output, EventEmitter, HostListener,
} from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator,
} from '@angular/forms';
import {
  filter, Subject, takeUntil, throttleTime,
} from 'rxjs';

@Component({
  selector: 'rw-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OtpInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: OtpInputComponent,
      multi: true,
    },
  ],
})
export class OtpInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, AfterViewInit {
  constructor(
  ) {
    this.otpLength = this.otpLength || 5;
    this.individualValue = [];
    this.keyUp$ = new Subject();
    this.destroy$ = new Subject();
  }

  @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>>;

  @Input() otpLength: number;

  @Output() getOTP = new EventEmitter<string>();

  public onChangeFn: (value: string) => undefined;

  public onTouchFn: () => void;

  private onValidatorChange: () => void;

  private individualValue: string[];

  private keyUp$: Subject<{ event: KeyboardEvent, index: number }>;

  private destroy$: Subject<boolean>;

  @Input() autoFocusInput: boolean;

  value: string;

  disabled: boolean;

  private changeFocus(key: string, index: number): void {
    let position = index;
    if (key === 'Backspace') position = index - 1;
    else position = index + 1;

    if (position > -1 && position < this.otpLength) this.inputs.get(position).nativeElement.focus();
  }

  private getOTPFinalValue() {
    let finalValue = '';
    for (let i = 0; i < this.otpLength; i++) {
      const { value } = this.inputs.get(i).nativeElement;
      finalValue += value;
    }
    this.onChangeFn(finalValue);
    this.getOTP.emit(finalValue);
  }

  private valueChanged(key: string, index: number): void {
    this.getOTPFinalValue();
    this.individualValue[index] = key;
    this.value = this.individualValue.join('');
    this.onChangeFn(this.value);
  }

  testRegex(key: string): boolean {
    const filterRegex = new RegExp(/^([a-zA-Z0-9]|Backspace)$/);
    // for mobile
    if (key?.toLowerCase() === 'unidentified') {
      return true;
    }
    return filterRegex.test(key);
  }
  ngOnInit(): void {
    this.keyUp$.pipe(
      takeUntil(this.destroy$),
      throttleTime(100),
      filter(({ event }) => this.testRegex(event.key)),
    ).subscribe(({ event, index }) => {
      this.valueChanged(event.key, index);
      this.changeFocus(event.key, index);
    });
  }

  ngAfterViewInit(): void {
    const firstInputElement = this.inputs.first?.nativeElement;
    if (firstInputElement && this.autoFocusInput) {
      firstInputElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  validate(control: AbstractControl): ValidationErrors {
    const { value } = control;
    if (value && value.length < this.otpLength) return { required: true };
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.value) this.individualValue = this.value.split('');
    else this.individualValue = [];
  }

  registerOnChange(fn: (value: string) => undefined): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    this.keyUp$.next({
      event,
      index,
    });
  }

  onFocus(): void {
    if (this.onTouchFn) this.onTouchFn();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pastedInput = event.clipboardData.getData('Text');
    const arr = pastedInput.split('');
    if (arr.length !== this.otpLength) return;
    for (let i = 0; i < this.otpLength; i++) {
      this.inputs.get(i).nativeElement.value = arr[i];
      this.inputs.get(i).nativeElement.focus();
    }
    this.getOTPFinalValue();
  }
}
