<form *ngIf="!smsOtpFeatureFlag"
  class="flex flex-column"
  [formGroup]="newPasswordFormGroup"
  #ngForm="ngForm"
>
  <div class="p-fluid grid">
    <div class="field col-12">
      <h3 [ngClass]="lang === 'ar' ? 'text-right' : ''">
        {{ 'Change your Password' | translate }}
      </h3>
    </div>
    <div class="field col-12">
      <span class="p-float-label" [ngClass]="lang === 'en' ? 'p-input-icon-right' : 'p-input-icon-left'">
        <i
          *ngIf="newPasswordFormGroup.get('verificationCode').value"
          aria-label="Clear"
          (click)="newPasswordFormGroup.get('verificationCode').patchValue('')"
          class="fa-solid fa-circle-xmark fa-lg form-clear-icon cursor-pointer"></i>
        <input pendo-tracker="npi"
          pInputText
          formControlName="verificationCode"
          type="text"
          id="verificationCode"
        />
        <label>{{ 'Confirmation Code' | translate }}</label>
      </span>
      <small
        *ngIf="verificationCode.touched && verificationCode.invalid"
        class="flex align-items-center p-error"
      >
        <i class="fa-regular fa-circle-exclamation mr-1"></i>
        <span> {{ 'Verification Code is required' | translate }} </span>
      </small>
      <small
        *ngIf="errMessage && verificationCode.valid"
        class="flex align-items-center p-error"
      >
        <i class="fa-regular fa-circle-exclamation mr-1"></i>
        <span> {{ errMessage | translate }}</span>
      </small>
      <p
        class="editEmail mt-1"
        *ngIf="userEmail && !errMessage"
        [ngClass]="lang === 'ar' ? 'text-right' : ''"
      >
        {{ 'Enter the 6-digit code sent to' | translate }}
        <strong>
          {{ userEmail }}
        </strong>
      </p>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label" [ngClass]="lang === 'en' ? 'p-input-icon-right' : 'p-input-icon-left'">
        <i
          *ngIf="newPasswordFormGroup.get('password').value"
          aria-label="Clear"
          (click)="newPasswordFormGroup.get('password').patchValue('')"
          class="fa-solid fa-circle-xmark fa-lg form-clear-icon cursor-pointer"></i>
        <input
          pInputText
          formControlName="password"
          [type]="isShowPassowd ? 'text' : 'password'"
          id="password" (keydown.space)="$event.preventDefault();"
        />
        <label>{{ 'Password' | translate }}</label>
      </span>
      <small
        *ngIf="password.touched && password.errors?.required"
        class="flex align-items-center p-error"
      >
        <i class="fa-regular fa-circle-exclamation mr-1"></i>
        <span> {{ 'Password is required' | translate }} </span>
      </small>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label" [ngClass]="lang === 'en' ? 'p-input-icon-right' : 'p-input-icon-left'">
        <i
          *ngIf="pwdConfirm.value"
          aria-label="Clear"
          (click)="pwdConfirm.patchValue('')"
          class="fa-solid fa-circle-xmark fa-lg form-clear-icon cursor-pointer"></i>
        <input
          pInputText
          formControlName="pwdConfirm"
          [type]="isShowPassowd ? 'text' : 'password'"
          id="pwdConfirm" (keydown.space)="$event.preventDefault();"
        />
        <label>{{ 'Confirm Password' | translate }}</label>
      </span>
      <small
        *ngIf="pwdConfirm.touched && pwdConfirm.errors?.required"
        class="flex align-items-center p-error"
      >
        <i class="fa-regular fa-circle-exclamation mr-1"></i>
        <span> {{ 'Confirm Password is required' | translate }} </span>
      </small>
    </div>
    <div class="field col-12">
      <p-checkbox
        value="isShowPassword"
        label="{{ 'Show Password' | translate }}"
        labelStyleClass="mb-0"
        (click)="showPassword()"></p-checkbox>
      <div
        *ngIf="
          pwdConfirm.touched &&
          !pwdConfirm.errors?.required &&
          password.value !== pwdConfirm.value
        "
        class="mt-2 flex align-items-center p-error"
        [ngClass]="changeLang === 'ar' ? 'text-right' : 'text-left'"
      >
        <small class="error-group">
          <i class="fa-regular fa-circle-exclamation mr-1"></i>
          <span>{{ 'Those passwords did not match. Try again.' | translate }}</span>
        </small>
      </div>
      <div
        *ngIf="password.invalid && (password.dirty || password.touched)"
        class="mt-2"
        [ngClass]="changeLang === 'ar' ? 'text-right' : 'text-left'"
      >
        <small
          *ngIf="password.errors?.pattern"
          class="p-error"
          [ngClass]="changeLang === 'ar' ? 'text-right' : 'text-left'"
        >
          <div class="error-group">
            <i class="fa-regular fa-circle-exclamation mr-1"></i>
            <span>{{ 'passwordValidation.requirements' | translate }}</span>
          </div>
        </small>
      </div>
    </div>
    <div class="field col-12 mb-0 flex align-items-center justify-content-between">
      <button
        pButton pRipple
        class="w-auto p-button-text p-button-lg rw__fs-8"
        [routerLink]="['/login']"
      >
        {{ 'Cancel' | translate }}
      </button>
      <button pButton pRipple
        [disabled]="
          newPasswordFormGroup.invalid ||
          isShowSpinner ||
          password.value !== pwdConfirm.value
        "
        [ngClass]="userEmail === '' ? 'disabled' : ''"
        type="button"
        (click)="setNewPassword(newPasswordFormGroup.value)"
        class="w-auto p-button-lg auth-btn rw__fs-8"
        label="{{ 'Change Password' | translate }}"
      >
      </button>
    </div>
  </div>
</form>

<form *ngIf="smsOtpFeatureFlag"
  [formGroup]="newPasswordFormGroup"
  #ngForm="ngForm"
  class="p-fluid grid auth-form"
>
  <div class="field col-12 rw__pb-0">
    <label class="rw__fs-6 fw-500 rw__mb-3">{{'Change your Password' | translate}} {{userPhone}}</label>
    <p class="rw__fs-9 text-grey-60 rw__mb-3" [innerHTML]="message"></p>
    <!-- <p class="rw__fs-9 text-grey-60 rw__mb-3">{{ 'auth.changeYourPasswordMessage' | translate: { email: userEmail || '' } }}</p> -->
  </div>
   <div class="field col-12 rw__mb-5">
    <div class="rw__otp rw__otp-w-full">
      <rw-otp-input [formControl]="otpFormControl" [otpLength]="6" (getOTP)="getOTP($event)" [autoFocusInput]="true"></rw-otp-input>
    </div>
    <div class="rw__mt-3 d-flex align-items-center justify-content-between w-full">
      <button pButton type="button" (click)="resendForgotPasswordCode()" [disabled]="timerVisible" class="rw__button p-button-text w-auto rw__px-0 bg-transparent" label="{{'auth.resendCodeTitle' | translate}}"
       ></button>
      <span *ngIf="timerVisible" class="rw__fs-9 text-grey-60 block" [innerHTML]="'resetTimer' | translate : {countDownSeconds: countdownSeconds}"></span>
    </div>       
    <rw-input-error *ngIf="(verificationCode.touched && verificationCode.invalid) || newPasswordFormGroup.get('verificationCode').errors?.cannotContainSpace"
      [text]="'Verification Code is required' | translate" class="block rw__mt-2"></rw-input-error>
      <rw-input-error *ngIf="errMessage"
      [text]="errMessage" class="block rw__mt-2"></rw-input-error>
  </div>    
  <div class="field col-12">
    <!-- PASSWORD INPUT -->
    <span class="rw__input rw__input-float rw__input-icon p-float-label w-full">
      <p-password pendo-tracker="npi-1" [formControl]="newPasswordFormGroup.get('password')" [(ngModel)]="passwordModel" [toggleMask]="true" [feedback]="false" [showClear]="true" required></p-password>
      <label for="password-input">
        <i class="text-danger">*</i>
        {{ 'New Password' | translate }}
      </label>
    </span>
    <ng-container *ngIf="password.invalid && (password.dirty || password.touched)">
      <div class="flex align-items-start mt-1">
        <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
        <small *ngIf="password.touched && password.errors?.required" class="p-error block mx-1">{{ 'Password is required' | translate }}</small>
        <small *ngIf="password.errors.pattern" class="p-error block mx-1">{{ 'passwordValidation.requirements' | translate }}</small>
      </div>
    </ng-container>
  </div>  
  <div class="field col-12">
    <!-- CHANGE PASSWORD INPUT -->
    <span class="rw__input rw__input-float rw__input-icon p-float-label w-full">
      <p-password pendo-tracker="npi-2" [formControl]="newPasswordFormGroup.get('pwdConfirm')" [(ngModel)]="confirmPasswordModel" [toggleMask]="true" [feedback]="false" [showClear]="true" required></p-password>
      <label for="confirm-password-input">
        <i class="text-danger">*</i>
        {{ 'Confirm Password' | translate }}
      </label>
    </span>
    <ng-container>
      <div *ngIf="pwdConfirm.touched && pwdConfirm.errors?.required" class="flex align-items-start mt-1">
        <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
        <small  class="p-error block mx-1">{{ 'Confirm Password is required' | translate }}</small>
      </div>
      <div *ngIf="pwdConfirm.touched && !pwdConfirm.errors?.required && password.value !== pwdConfirm.value" class="flex align-items-start mt-1">
        <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
        <small  class="p-error block mx-1">{{ 'Those passwords did not match. Try again.' | translate }}</small>
      </div>
    </ng-container>
  </div>  
  <div class="field col-12 rw__mb-0 text-center">
    <button  pendo-tracker="npb-1" pButton type="button" 
    class="rw__button" 
    [ngClass]="userEmail === '' ? 'disabled' : ''"
    label="{{'Change Password' | translate}}"
      (click)="setNewPassword(newPasswordFormGroup.value)" 
      [disabled]="isShowSpinner || password.value !== pwdConfirm.value || !vButtonVisibility || password.invalid"></button>
    <div class="rw__my-5 d-flex align-items-center w-full"
      [ngClass]="sendToEmailButtonVisibility ? 'justify-content-between' : 'justify-content-center'">
      <button pendo-tracker="npb"
        pButton pRipple
        [routerLink]="['/login']"
        label="{{'Cancel' | translate}}"
        class="rw__button p-button-text w-auto rw__px-0 bg-transparent minw-0"
      >
      </button>
      <button 
      *ngIf="sendToEmailButtonVisibility"
      (click)="sendToEmailInstead()" 
      pButton pRipple
      [disabled]="isShowSpinner"
      class="w-auto p-button-text p-button-lg rw__fs-8"
      label="{{'Send to Email Instead' | translate}}"
      class="rw__button p-button-text w-auto rw__px-0 bg-transparent minw-0"
    >
    </button>
    </div>       
    <hr class="rw__mb-4" />
    <span class="rw__my-3">
      {{ 'Need Help?' | translate}} 
      <button  class="rw__button text-primary border-0 bg-transparent p-button-text" (click)="startIntercom()">{{
        'Contact Support' | translate
      }}</button>
    </span>
  </div>
</form>