import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { StockLocation } from 'src/app/shared/model/StockLocation';
import {
  PaymentMethodActionResult,
  PaymentMethod,
} from 'src/app/shared/model/PaymentMethod';
import {
  FiscalPeriodTimePeriod,
  FiscalPeriodStatus,
  SortOrder,
  isDateInFiscalPeriodRange,
} from '@rewaa-team/rewaa-common';
import { Location } from '../../shared/model/Location';
import { Merchant } from '../../shared/model/Merchant';
import { WeightedProductConfiguration } from '../../shared/model/WeightedProductConfiguration';
import { ShippingConfiguration } from '../../shared/model/ShippingConfiguration';
import { QuantityConfiguration } from '../../shared/model/QuantityConfiguration';
import { Tax } from '../../shared/model/Tax';
import { TaxConfiguration } from '../../shared/model/TaxConfiguration';
import { Bank } from '../../shared/model/Banks';
import { PaymentLink } from '../../shared/model/Banks copy';
import {
  FiscalPeriod,
  FiscalPeriodListing,
} from '../../shared/model/fiscal-period';
import { CloseFiscalPeriod } from '../../shared/types/fiscal-period';
import {
  GetAllPermissionsDetailsWithRoles,
  MerchantPermissionsResponseDTO,
} from '../components/user-v2/types';

const API_URL = '/api';

const MIMS_EXTENDED_API_URL = '/api/mims-extended';

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) {}

  getAllStoreUsers(): Observable<Merchant[]> {
    return this.http.get<Merchant[]>(`${API_URL}/settings/users`);
  }

  getAllStoreUsersV2(): Observable<Merchant[]> {
    return this.http.get<Merchant[]>(`${API_URL}/nucleus/merchants/settings/users`);
  }

  getAllStockLocations(): Observable<any[]> {
    return this.http.get<any[]>(`${API_URL}/stock-location`);
  }

  getStockLocationsById(id): Observable<any> {
    return this.http.get<StockLocation>(`${API_URL}/stock-location/${id}`);
  }

  getAllStockLocationsIgnorePermission(): Observable<any[]> {
    return this.http.get<any[]>(`${API_URL}/stock-location/all`);
  }

  getCompanyOverview(): Observable<Merchant> {
    return this.http.get<Merchant>(`${API_URL}/settings/overview`);
  }

  updateCompanyOverview(updatedOverview): Observable<Merchant> {
    return this.http.put<Merchant>(
      `${API_URL}/settings/overview`,
      updatedOverview,
    );
  }

  getUser(userId): Observable<string> {
    return this.http.get<string>(`${API_URL}/users/user-name/${userId}`);
  }

  updateUser(userId, updatedUser): Observable<Merchant> {
    return this.http.put<Merchant>(
      `${API_URL}/settings/users/${userId}`,
      updatedUser,
    );
  }

  addUser(user): Observable<Merchant> {
    return this.http.post<Merchant>(`${API_URL}/settings/users`, user);
  }

  getCountries(): Observable<any> {
    return this.http.get(`${API_URL}/lookups/countries`);
  }

  updateLocation(locationId: number, updatedLocation): Observable<Location> {
    return this.http.put<Location>(
      `${API_URL}/stock-location/${locationId}`,
      updatedLocation,
    );
  }

  getAllPaymentMethods(query?: {
    showSoftPos: boolean;
  }): Observable<PaymentMethod[]> {
    const queryParams = new HttpParams().set(
      'showSoftPos',
      query?.showSoftPos || false,
    );
    return this.http.get<PaymentMethod[]>(`${API_URL}/payment-methods/list`, {
      params: queryParams,
    });
  }

  addPaymentMethod(
    paymentMethod: PaymentMethod,
  ): Observable<PaymentMethodActionResult> {
    return this.http.post<PaymentMethodActionResult>(
      `${API_URL}/payment-methods/add`,
      paymentMethod,
    );
  }

  updatePaymentMethod(
    paymentMethodId: number,
    updatedPaymentMethod,
  ): Observable<PaymentMethodActionResult> {
    return this.http.put<PaymentMethodActionResult>(
      `${API_URL}/payment-methods/${paymentMethodId}`,
      updatedPaymentMethod,
    );
  }

  createLocation(location: Location): Observable<Location> {
    return this.http.post<Location>(`${API_URL}/stock-location`, location);
  }

  getAllLocationStock(): Observable<any[]> {
    return this.http.get<any[]>(`${API_URL}/all-location-stocks`);
  }

  getWeightedProductConfiguration(): Observable<WeightedProductConfiguration> {
    return this.http.get<WeightedProductConfiguration>(
      `${API_URL}/configurations/weighted-product`,
    );
  }

  updateWeightedProductConfiguration(
    newConfig: WeightedProductConfiguration,
  ): Observable<WeightedProductConfiguration> {
    return this.http.put<WeightedProductConfiguration>(
      `${API_URL}/configurations/weighted-product`,
      newConfig,
    );
  }

  getShippingConfiguration(): Observable<ShippingConfiguration> {
    return this.http.get<ShippingConfiguration>(
      `${API_URL}/configurations/shipping`,
    );
  }

  updateShippingConfiguration(newConfig): Observable<ShippingConfiguration> {
    return this.http.put<ShippingConfiguration>(
      `${API_URL}/configurations/shipping`,
      newConfig,
    );
  }

  getRolePermissions(name: string): Observable<Array<number>> {
    return this.http.get<Array<number>>(
      `${API_URL}/settings/roles/${name}/permissions`,
    );
  }

  getUserLocations(userId): Observable<Array<StockLocation>> {
    return this.http.get<Array<StockLocation>>(
      `${API_URL}/settings/users/${userId}/locations`,
    );
  }

  getQuantityConfiguration(): Observable<QuantityConfiguration> {
    return this.http.get<QuantityConfiguration>(
      `${API_URL}/configurations/quantity`,
    );
  }

  getProductConfigurations(): Observable<{
    quantityConfiguration: QuantityConfiguration;
    shippingConfiguration: ShippingConfiguration;
    weightedProductConfiguration: WeightedProductConfiguration;
    taxConfiguration: TaxConfiguration;
    taxes: Tax[];
  }> {
    return this.http.get<{
      quantityConfiguration: QuantityConfiguration;
      shippingConfiguration: ShippingConfiguration;
      weightedProductConfiguration: WeightedProductConfiguration;
      taxConfiguration: TaxConfiguration;
      taxes: Tax[];
    }>(`${API_URL}/product/configurations`);
  }

  updateQuantityConfiguration(
    quantityConfiguration,
  ): Observable<QuantityConfiguration> {
    return this.http.put<QuantityConfiguration>(
      `${API_URL}/configurations/quantity`,
      quantityConfiguration,
    );
  }

  getBanks(): Observable<Bank[]> {
    return this.http.get<Bank[]>(`${API_URL}/banks`);
  }

  addBank(bank: Bank): Observable<Bank> {
    return this.http.post<Bank>(`${API_URL}/bank`, bank);
  }

  updatePaymentMethodToBankLink(paymentLinks: PaymentLink[]): Observable<any> {
    return this.http.put<any>(`${API_URL}/payment-methods-link`, paymentLinks);
  }

  getCopingProgress(
    sourceStockLocationId: number,
    destinationStockLocationId: number,
  ): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/stock-location/copyingStocksProgress?sourceStockLocationId=${sourceStockLocationId}&destinationStockLocationId=${destinationStockLocationId}`,
    );
  }

  async getFiscalPeriods(queryParams?: {
    status?: FiscalPeriodStatus[];
    limit?: number;
    offset?: number;
    sortOrder?: SortOrder;
  }): Promise<FiscalPeriodListing> {
    const params = new HttpParams({ fromObject: queryParams });
    return firstValueFrom(
      this.http.get<FiscalPeriodListing>(`${API_URL}/fiscal-periods`, {
        params,
      }),
    );
  }

  async isFiscalPeriodClosed(date: Date): Promise<boolean> {
    const closedFiscalPeriods = (
      await this.getFiscalPeriods({
        status: [FiscalPeriodStatus.CLOSE],
      })
    ).fiscalPeriods;
    if (closedFiscalPeriods) {
      return isDateInFiscalPeriodRange(date, closedFiscalPeriods);
    }
    return false;
  }

  getFiscalPeriod(id: number): Promise<FiscalPeriod> {
    return firstValueFrom(
      this.http.get<FiscalPeriod>(`${API_URL}/fiscal-period/${id}`),
    );
  }

  closeAndCreateNewFiscalPeriod(
    params: CloseFiscalPeriod,
  ): Promise<FiscalPeriod[]> {
    return firstValueFrom(
      this.http.patch<FiscalPeriod[]>(
        `${API_URL}/fiscal-period/${params.id}`,
        params,
      ),
    );
  }

  deleteAndRecreateFiscalPeriod(
    id: number,
    params: FiscalPeriod,
  ): Promise<FiscalPeriod> {
    return firstValueFrom(
      this.http.patch<FiscalPeriod>(
        `${API_URL}/fiscal-period/${id}/delete-and-recreate`,
        params,
      ),
    );
  }

  async createFiscalPeriod(
    fiscalPeriod: FiscalPeriod,
  ): Promise<FiscalPeriod[]> {
    return firstValueFrom(
      this.http.post<FiscalPeriod[]>(`${API_URL}/fiscal-period`, fiscalPeriod),
    );
  }

  editFiscalPeriod(
    id: number,
    fiscalPeriod: { timePeriod: FiscalPeriodTimePeriod },
  ): Promise<FiscalPeriod[]> {
    return firstValueFrom(
      this.http.put<FiscalPeriod[]>(
        `${API_URL}/fiscal-period/${id}`,
        fiscalPeriod,
      ),
    );
  }

  reopenFiscalPeriod(id: number): Promise<FiscalPeriod> {
    return firstValueFrom(
      this.http.patch<FiscalPeriod>(
        `${API_URL}/fiscal-period/${id}/reopen`,
        {},
      ),
    );
  }

  getAllPermissionDetails(): Observable<GetAllPermissionsDetailsWithRoles> {
    return this.http.get<GetAllPermissionsDetailsWithRoles>(
      `${MIMS_EXTENDED_API_URL}/permissions/all-details`,
    );
  }

  getPermissionsByMerchantId(
    merchantId: number,
  ): Observable<MerchantPermissionsResponseDTO> {
    const params = new HttpParams().set('merchantId', merchantId);
    return this.http.get<MerchantPermissionsResponseDTO>(
      `${MIMS_EXTENDED_API_URL}/merchants/permissions`,
      { params },
    );
  }
}
