import { TenantIdHeader } from '../constants/common-constants';
import { RewaaApi } from '../internals/rewaa-api';
import {
  AllProductVariantRequest,
  RewaaApiResponse,
  RewaaPagedApiResponse,
} from '../internals/types/base-api-types';
import {
  IMainMerchantByEmailResponse,
  IMainMerchantDetailsResponse,
  IMerchantSubscriptionStatusResponse,
  IStockLocation,
  MerchantDataPermissionsPayload,
  PromotionItemValidationInput,
  PromotionItemValidationOutput,
  ICreateProductResponse,
  UpdateProductRequest,
  IUpdateProductResponse,
  CreateProductRequest,
  IStockLocationsResponse,
  ProductByIdResponse,
  ProductVariantResponse,
  IGetRegistersResponse,
  PaymentMethod,
  FiscalPeriodsListing,
  AccountStats,
  ProductVariantInput,
  GetProductByVariantIdsReqDto,
  GetProductByVariantIdsResDto,
  CreateProductForMerchantReq,
  CreateProductForMerchantRes,
  ZohoResponse,
  CheckoutOnlinePaymentData,
  UpdatePartialPaymentDTO,
  AppSubscriptionStatus,
} from '../internals/types/mims-service-types';

export class MimsApiService extends RewaaApi {
  constructor(apiUrl: string, apiKey: string) {
    super(apiUrl, apiKey);
  }

  private usernameHeaderKey = 'vnd.rewaatech.username';
  async createProduct(
    username: string,
    createProductRequest: CreateProductRequest,
  ): Promise<RewaaApiResponse<ICreateProductResponse>> {
    return this.post<ICreateProductResponse>(
      `/partner/products`,
      createProductRequest,
      {},
      this.createUsernameHeader(username),
    );
  }
  async updateProduct(
    username: string,
    updateProductRequest: UpdateProductRequest,
  ): Promise<RewaaApiResponse<IUpdateProductResponse>> {
    return this.put<IUpdateProductResponse>(
      `/partner/products/${updateProductRequest.id}`,
      updateProductRequest,
      {},
      this.createUsernameHeader(username),
    );
  }

  async getStockLocationsOfMerchant(
    username: string,
  ): Promise<RewaaApiResponse<IStockLocationsResponse>> {
    return this.get<IStockLocationsResponse>(
      `/partner/stock-locations`,
      {},
      this.createUsernameHeader(username),
    );
  }
  async getRegisters(
    username: string,
  ): Promise<RewaaApiResponse<IGetRegistersResponse>> {
    return this.get<IGetRegistersResponse>(
      `/partner/registers`,
      {},
      this.createUsernameHeader(username),
    );
  }

  private createUsernameHeader(username: string) {
    return { [this.usernameHeaderKey]: username };
  }

  private createTenantIdHeader(tenantId: string) {
    return { [TenantIdHeader]: tenantId };
  }

  async getMainMerchantBySchema(schemaName: string) {
    return this.get<IMainMerchantDetailsResponse>(
      `/merchants/main/${schemaName}`,
    );
  }

  async getProductVariant(input: ProductVariantInput) {
    return this.post<any>('/products/get-product-variant', input);
  }
  async getProductVariantsByVariantIds(
    input: GetProductByVariantIdsReqDto,
  ): Promise<RewaaApiResponse<GetProductByVariantIdsResDto[]>> {
    const path = `/merchants/${input.schemaName}/products?variantIds=`.concat(
      input.variantIds.length > 0 ? `${input.variantIds}` : '',
      `&skus=${input.skus}`,
    );
    return this.get<GetProductByVariantIdsResDto[]>(path);
  }

  async createProductForMerchant(
    schemaName: string,
    createProductRequest: CreateProductForMerchantReq,
  ): Promise<RewaaApiResponse<CreateProductForMerchantRes>> {
    return this.post<CreateProductForMerchantRes>(
      `/merchants/${schemaName}/products`,
      createProductRequest,
    );
  }
  async getMainMerchantByEmail(email: string) {
    return this.get<IMainMerchantByEmailResponse>(`/merchants/email/${email}`);
  }

  async getMerchantSubscriptionStatus(schemaName: string) {
    return this.get<IMerchantSubscriptionStatusResponse>(
      `/merchants/subscription-status/${schemaName}`,
    );
  }

  async getAllLocationsOfMerchant(
    schemaName: string,
  ): Promise<RewaaApiResponse<IStockLocation[]>> {
    return this.get<IStockLocation[]>(`/admin/stock-location/${schemaName}`);
  }

  async getPromotionItemValidationData(
    body: PromotionItemValidationInput,
    schemaName: string,
  ): Promise<RewaaApiResponse<PromotionItemValidationOutput>> {
    return this.post<PromotionItemValidationOutput>(
      `/category/promotion-items/${schemaName}`,
      body,
    );
  }

  async getMerchantDataPermissions(
    payload: MerchantDataPermissionsPayload,
  ): Promise<RewaaApiResponse<number[]>> {
    const { email, schemaName, dataPermissionType, forPOS } = payload;
    return this.get<number[]>(
      `/merchants/data-permissions?dataPermissionType=${dataPermissionType}&forPOS=${forPOS}`,
      {},
      { email, schemaName },
    );
  }

  async getProductById(
    productId: number,
    userName: string,
  ): Promise<RewaaApiResponse<ProductByIdResponse>> {
    return this.get(
      `/partner/products/${productId}`,
      {},
      this.createUsernameHeader(userName),
    );
  }

  async getAllProductVariant(
    productVariantRequest: AllProductVariantRequest,
  ): Promise<RewaaApiResponse<RewaaPagedApiResponse<ProductVariantResponse>>> {
    const { userName, ...params } = productVariantRequest;
    return this.get(
      `/partner/products-v4`,
      params,
      this.createUsernameHeader(userName),
    );
  }

  async getPaymentMethods(
    tenantId: number,
  ): Promise<RewaaApiResponse<PaymentMethod[]>> {
    return await this.get(
      '/tenant/payment-methods',
      {},
      this.createTenantIdHeader(String(tenantId)),
    );
  }

  async getAppsSubscriptionStatusForTenant(
    tenantId: number,
  ): Promise<RewaaApiResponse<AppSubscriptionStatus[]>> {
    return await this.get(
      '/tenant/apps-subscription-status',
      {},
      this.createTenantIdHeader(String(tenantId)),
    );
  }

  async getFiscalPeriods(
    tenantId: number,
  ): Promise<RewaaApiResponse<FiscalPeriodsListing>> {
    return await this.get(
      '/tenant/fiscal-periods',
      {},
      this.createTenantIdHeader(String(tenantId)),
    );
  }

  async resetMerchantDatabase(
    schemaName: string,
  ): Promise<RewaaApiResponse<void>> {
    return await this.post(`mims-extended/merchants/reset-data`, {
      schemaName,
    });
  }

  async getAccountStats(
    schemaName: string,
  ): Promise<RewaaApiResponse<AccountStats>> {
    return await this.get(`mims-extended/admin-panel/account-stats`, {
      schemaName,
    });
  }

  async generateSku(): Promise<RewaaApiResponse<string[]>> {
    return await this.get('/variants/nextSKUCode', { productSku: 'null' });
  }

  async checkoutOnlinePaymentOnZoho(
    data: CheckoutOnlinePaymentData,
  ): Promise<RewaaApiResponse<ZohoResponse>> {
    return await this.post(
      `mims-extended/checkout/admin-payment-receipt`,
      data,
    );
  }

  async updatePartialPaymentOnZoho(
    data: UpdatePartialPaymentDTO,
  ): Promise<RewaaApiResponse<void>> {
    return await this.post(
      `mims-extended/checkout/update-partial-payment`,
      data,
    );
  }

  async getCompanyOverview(tenantId: number): Promise<{
    logoUrl?: string;
  }> {
    const resp = await this.get<{
      logoUrl?: string;
    }>(
      '/tenant/company-overview',
      {},
      this.createTenantIdHeader(String(tenantId)),
    );
    return resp.data;
  }
}
