export const DiscountTypeConstant = {
  Invoice: 'Invoice',
  Product: 'Product',
  Promotion: 'Promotion',
} as const;

export type DiscountType =
  (typeof DiscountTypeConstant)[keyof typeof DiscountTypeConstant];

export const TaxMethodConstant = {
  Simple: 'Simple',
  Compound: 'Compound',
} as const;

export type TaxMethod =
  (typeof TaxMethodConstant)[keyof typeof TaxMethodConstant];

export type ErrorCodes =
  (typeof ErrorCodesConstant)[keyof typeof ErrorCodesConstant];

export const ErrorCodesConstant = {
  DiscountLimitExceeded: 'discountLimitExceeded',
  CostGreaterThanPrice: 'costGreaterThanPrice',
  InsufficientStock: 'insufficientStock',
  OutOfStock: 'outOfStock',
  QuantityExceeded: 'quantityExceeded',
  PriceExceeded: 'priceExceeded',
} as const;

export const MaxAllowed = {
  Quantity: 99999999,
  Price: 99999999999,
};