import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FeatureFlags } from '../enum/feature-flag.interface';
import { FeatureFlagService } from '../types/feature-flag.service.interface';
import { environment } from '../../../../environments/environment';

type FeatureFlagResponse = {
  key: string;
  value: any;
};

@Injectable()
export class FeatureFlagMockService implements FeatureFlagService {
  private readonly flagValues: Record<string, any> = {};

  private readonly APP_URL = '/api/feature-flag';

  private readonly headers = {
    'x-api-key': environment.automationTestFFAPIKey ?? '1234',
  };

  constructor(private http: HttpClient) {
    try {
      // eslint-disable-next-line global-require
      this.flagValues = require('flag_values.json');
    } catch (err) {
      if (environment.mockLaunchDarkly) {
        throw err;
      }
    }
  }

  isEnabled<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: any,
  ): Observable<FeatureFlags[T]> {
    if (environment.enableAutomationTestFF) {
      const localStorageValue = JSON.parse(
        localStorage.getItem(featureFlagName),
      );
      console.log(
        `From local storage | ${featureFlagName}:${localStorageValue}`,
      );
      if (localStorageValue !== null) {
        return of(localStorageValue);
      }
      return this.http
        .get<FeatureFlagResponse>(
          `${this.APP_URL}/get?key=${featureFlagName}`,
          {
            headers: this.headers,
          },
        )
        .pipe(
          map((response) => response.value ?? defaultValue),
          catchError((error) => {
            console.error(
              'Error fetching feature flag value from the endpoint:',
              error,
            );
            return throwError(error);
          }),
        );
    }
    return of(this.flagValues[featureFlagName] ?? defaultValue);
  }

  variation<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: any,
  ): Observable<FeatureFlags[T]> {
    if (environment.enableAutomationTestFF) {
      const localStorageValues = JSON.parse(
        localStorage.getItem(featureFlagName),
      );
      console.log(
        `From local storage | ${featureFlagName}:${localStorageValues}`,
      );
      if (localStorageValues !== null) {
        return of(localStorageValues);
      }
      return this.http
        .get<FeatureFlagResponse>(
          `${this.APP_URL}/get?key=${featureFlagName}`,
          {
            headers: this.headers,
          },
        )
        .pipe(
          map((response) => response.value || defaultValue),
          catchError((error) => {
            console.error(
              'Error fetching feature flag value from the endpoint:',
              error,
            );
            return throwError(error);
          }),
        );
    }
    return of(this.flagValues[featureFlagName] ?? defaultValue);
  }
}
