import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaginationQuery } from 'src/app/shared/model/PaginationQuery';
import {
  downloadFileFromUrl,
  IDownloadFile,
} from '../../shared/utility/file.utility';
import { Expense } from '../model/expense';
import {
  ExpenseCategories,
  ExpenseStopResponse,
  Identifier,
  PaymentMethodIdentifier,
  RegisterIdentifier,
  TaxMethodIdentifier,
} from '../model/expense.types';

const API_URL = '/api';

@Injectable()
export class ExpenseService {
  constructor(private http: HttpClient) {}

  getExpense(expenseId: number): Observable<Expense> {
    return this.http
      .get<Expense>(`${API_URL}/expenses/${expenseId}`)
      .pipe(map((response) => new Expense(response)));
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getAllExpenses(query): Observable<{ result: Expense[]; total: number }> {
    const productQueryParams = new HttpParams()
      .set('id', query.id ?? '')
      .set('offset', query.offset ? query.offset : 0)
      .set('limit', query.limit ?? '')
      .set('excludeChildRecords', query.excludeChildRecords ?? true)
      .set('parentId', query.parentId ?? '')
      .set('search', query.search ?? '')
      .set('searchOP', query.searchOP ?? '')
      .set('sortBy', query.sortBy ?? '')
      .set('amount', query.amount ?? '')
      .set('amountOP', query.amountOP ?? '')
      .set('taxAmount', query.taxAmount ?? '')
      .set('taxAmountOP', query.taxAmountOP ?? '')
      .set('expenseCategory', query.expenseCategory ?? '')
      .set('location', query.location ?? '')
      .set('paymentMethod', query.paymentMethod ?? '')
      .set('taxable', query.taxable ?? '')
      .set('isRecurring', query.isRecurring ?? '')
      .set('recurringCycle', query.recurringCycle ?? '')
      .set('recurringStartDateFromDate', query.recurringStartDateFromDate ?? '')
      .set('recurringStartDateToDate', query.recurringStartDateToDate ?? '')
      .set('recurringEndDateFromDate', query.recurringEndDateFromDate ?? '')
      .set('recurringEndDateToDate', query.recurringEndDateToDate ?? '')
      .set('fromDate', query.fromDate ?? '')
      .set('toDate', query.toDate ?? '')
      .set('createdAtFromDate', query.createdAtFromDate ?? '')
      .set('createdAtToDate', query.createdAtToDate ?? '')
      .set('updatedAtFromDate', query.updatedAtFromDate ?? '')
      .set('updatedAtToDate', query.updatedAtToDate ?? '');

    return this.http.get<{ result: Expense[]; total: number }>(
      `${API_URL}/expenses`,
      { params: productQueryParams },
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  exportAllExpenses({
    lang,
    search,
    searchOP,
    sortBy,
    amount,
    amountOP,
    expenseCategory,
    location,
    paymentMethod,
    taxable,
    taxAmount,
    taxAmountOP,
    fromDate,
    toDate,
    createdAtFromDate,
    createdAtToDate,
    updatedAtFromDate,
    updatedAtToDate,
  }: {
    lang?: string;
    search?: string;
    searchOP?: string;
    sortBy?: string;
    amount?: string;
    amountOP?: string;
    expenseCategory?: string;
    location?: string;
    paymentMethod?: string;
    taxable?: string;
    taxAmount?: string;
    taxAmountOP?: string;
    fromDate?: string;
    toDate?: string;
    createdAtFromDate?: string;
    createdAtToDate?: string;
    updatedAtFromDate?: string;
    updatedAtToDate?: string;
  }): Observable<IDownloadFile> {
    const productQueryParams = new HttpParams()
      .set('lang', lang || '')
      .set('search', search || '')
      .set('searchOP', searchOP || '')
      .set('sortBy', sortBy || '')
      .set('amount', amount || '')
      .set('amountOP', amountOP || '')
      .set('expenseCategory', expenseCategory || '')
      .set('location', location || '')
      .set('paymentMethod', paymentMethod || '')
      .set('taxable', taxable || '')
      .set('taxAmount', taxAmount || '')
      .set('taxAmountOP', taxAmountOP || '')
      .set('fromDate', fromDate || '')
      .set('toDate', toDate || '')
      .set('createdAtFromDate', createdAtFromDate || '')
      .set('createdAtToDate', createdAtToDate || '')
      .set('updatedAtFromDate', updatedAtFromDate || '')
      .set('updatedAtToDate', updatedAtToDate || '');

    return this.http
      .get<IDownloadFile>(`${API_URL}/expenses/export`, {
        params: productQueryParams,
      })
      .pipe(tap((response) => downloadFileFromUrl(response).subscribe()));
  }

  getExpenseCategories(): Observable<ExpenseCategories[]> {
    return this.http
      .get<ExpenseCategories[]>(`${API_URL}/expense-categories`)
      .pipe(
        map((response) =>
          response.map(
            (e) => <ExpenseCategories>{ id: e.id, name: e.name, slug: e.slug },
          ),
        ),
      );
  }

  getStockLocations(): Observable<Identifier[]> {
    return this.http
      .get<Identifier[]>(`${API_URL}/stock-location`)
      .pipe(
        map((response) =>
          response.map((e) => <Identifier>{ id: e.id, name: e.name }),
        ),
      );
  }

  getStockLocationRegister(
    locationId: number,
  ): Observable<RegisterIdentifier[]> {
    return this.http
      .get<RegisterIdentifier[]>(
        `${API_URL}/stock-location/${locationId}/pos/registers`,
      )
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <RegisterIdentifier>{
                id: e.id,
                name: e.name,
                status: e.status,
                PaymentMethodToRegisters: e.PaymentMethodToRegisters,
              },
          ),
        ),
      );
  }

  getTaxes(): Observable<TaxMethodIdentifier[]> {
    return this.http.get<TaxMethodIdentifier[]>(`${API_URL}/taxes`).pipe(
      map((response) =>
        response.map(
          (e) =>
            <TaxMethodIdentifier>{
              id: e.id,
              name: e.name,
              rate: e.rate,
            },
        ),
      ),
    );
  }

  getTaxLists(): Observable<any[]> {
    return this.http.get<Identifier[]>(`${API_URL}/taxes`);
  }

  getTaxById(taxId: number): Observable<any> {
    return this.http.get(`${API_URL}/taxes/${taxId}`);
  }

  getPaymentMethods(): Observable<PaymentMethodIdentifier[]> {
    return this.http
      .get<PaymentMethodIdentifier[]>(`${API_URL}/payment-methods/list`)
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <PaymentMethodIdentifier>{
                id: e.id,
                name: e.name,
                type: e.type,
              },
          ),
        ),
      );
  }

  createExpense(payload: Expense): Observable<any> {
    return this.http.post(`${API_URL}/expenses/create`, payload);
  }

  updateExpense(payload: Expense): Observable<any> {
    return this.http.put(`${API_URL}/expenses/${payload.id}`, payload);
  }

  getExpenses(
    pageQuery: PaginationQuery,
  ): Observable<{ result: Expense[]; total: number }> {
    return new Observable((subscriber) => {
      const { offset, limit, query = '' } = pageQuery;
      const requestPromise = firstValueFrom(
        this.http.get<{ result: Expense[]; total: number }>(
          `${API_URL}/expenses?query=${query}&limit=${limit}&offset=${offset}`,
        ),
      );

      requestPromise.then((data: { result: Expense[]; total: number }) => {
        subscriber.next(data);
      });
    });
  }

  deleteExpense(id: number): Observable<any> {
    return this.http.delete(`${API_URL}/expenses/${id}`);
  }

  stopExpense(id: number): Promise<ExpenseStopResponse> {
    return firstValueFrom(
      this.http.put<ExpenseStopResponse>(`${API_URL}/expenses/stop/${id}`, {}),
    );
  }
}
