import {
  InvoiceSnapshotTypes,
  ProductEnums,
  StockLocationTypes,
} from '@rewaa-team/types';
import { CustomFieldModule } from '../common/types';
import {
  CartEnums,
  CartTypes,
  InvoiceEnums,
  commonCalculationService,
} from '../index';
import { PaymentMethodIdConstant } from '../invoice/enums';
import {
  CreateInvoiceSnapshotOptions,
  CustomFieldData,
  Customer,
  IOfflineInvoiceData,
  InvoiceCreationPayload,
} from '../invoice/types';

export class InvoiceSnapshotService {
  public createInvoiceSnapshot(
    input: InvoiceCreationPayload,
    options: CreateInvoiceSnapshotOptions,
  ): InvoiceSnapshotTypes.InvoiceSnapshot {
    const orderNumber = options?.orderNumber || input.offlineData?.orderNumber;
    const isReturn = !!options.isReturn;
    const customFields = input.customFieldsData || [];
    const { otherValues } = input || {};

    return {
      commentImageUrl:
        (input.templateSettings.showCommentImage &&
          input.templateSettings.commentImage) ||
        '',
      completedAt: input.completionDate || new Date().toISOString(),
      customFields: this.mapCustomFields(
        customFields.filter(
          (field) => field.module === CustomFieldModule.Sales,
        ),
      ),
      customer: input.customer ? this.mapCustomer(input.customer) : undefined,
      dateOfSupply: input.dateOfSupply,
      id: options.invoiceId,
      invoiceNumber: options.invoiceNumber,
      lineItems: this.mapLineItems(input.cart.lineItems, isReturn),
      merchant: options.merchant,
      merchantLocation: options.merchantLocation,
      note: input.notes || '',
      orderNumber: orderNumber
        ? this.getOrderNumber(orderNumber, input.registerId, input.offlineData)
        : undefined,
      payments: options.payments
        .map((payment) => {
          return {
            ...payment,
            paymentAmount: this.negativeIfReturn(
              payment.paymentAmount,
              isReturn &&
                payment.methodId !== PaymentMethodIdConstant.CustomerDebit,
            ),
          };
        })
        .filter(
          (payment) =>
            (!isReturn &&
              payment.methodName !==
                InvoiceEnums.PaymentMethodConstant.CustomerDebit) ||
            isReturn,
        ),
      saleInvoiceNumber: options.saleInvoiceNumber,
      summary: {
        change: options.change,
        receivedAmount: options.receivedAmount,
        total: this.negativeIfReturn(input.cart.amountSummary.total, isReturn),
        subtotal: this.negativeIfReturn(
          input.cart.amountSummary.subtotal,
          isReturn,
        ),
        totalDiscount: this.negativeIfReturn(
          input.cart.amountSummary.totalDiscounts,
          isReturn,
        ),
        totalOtherDiscount: this.negativeIfReturn(
          commonCalculationService.add(
            input.cart.discountSummary.Invoice,
            input.cart.discountSummary.Product,
          ),
          isReturn,
        ),
        totalTax: this.negativeIfReturn(
          input.cart.amountSummary.totalTax,
          isReturn,
        ),
        discounts: this.mapDiscounts(input.cart.discountSummary, isReturn),
        // TBD
        taxes: this.mapTaxes(input.cart.taxSummary, isReturn),
        totalDebitAmount: this.negativeIfReturn(
          input.payments.reduce((total, payment) => {
            total +=
              payment.type === InvoiceEnums.PaymentTypeConstant.Debit
                ? payment.amount
                : 0;
            return total;
          }, 0),
          isReturn,
        ),
        totalItems: input.cart.lineItems.length,
      },
      templateSetting: input.templateSettings,
      templateVersionNumber: options.templateVersionNumber,
      userId: options.userId,
      username: options.username,
      type: options.isReturn
        ? InvoiceSnapshotTypes.ReceiptTypeConstant.CreditNote
        : input.templateSettings.sellOnB2B
        ? InvoiceSnapshotTypes.ReceiptTypeConstant.TaxableInvoice
        : InvoiceSnapshotTypes.ReceiptTypeConstant.SimpleTaxableInvoice,
      invoiceType: this.mapInvoiceType(isReturn),
      isOffline: input.offlineData?.isOffline,
      ...otherValues,
    };
  }

  mapInvoiceType(isReturn: boolean): InvoiceSnapshotTypes.InvoiceType {
    return isReturn
      ? InvoiceSnapshotTypes.InvoiceTypeConstant.ReturnInvoice
      : InvoiceSnapshotTypes.InvoiceTypeConstant.SellInvoice;
  }

  mapDiscounts(
    discountSummary: CartTypes.DiscountSummary,
    isReturn: boolean,
  ): CartTypes.DiscountSummary {
    const invoiceDiscount = isReturn ? 0 : discountSummary.Invoice;
    return {
      ...discountSummary,
      [CartEnums.DiscountTypeConstant.Invoice]: invoiceDiscount,
    };
  }

  getOrderNumber(
    orderNumber: number,
    registerId?: number,
    offlineData?: IOfflineInvoiceData,
  ): string {
    if (!offlineData) {
      return orderNumber.toString();
    }
    if (offlineData.offlineFirst) {
      return orderNumber.toString();
    }
    if (!offlineData.offlineFirst && registerId) {
      return `${registerId}-${orderNumber}`;
    }
    return orderNumber.toString();
  }

  private mapCustomFields(
    customFields: CustomFieldData[],
  ): InvoiceSnapshotTypes.InvoiceCustomField[] {
    return customFields
      .filter(
        (field) => field.customFieldJSON?.addToReceiptOption && field.value,
      )
      .map((field) => ({
        customFieldId: field.customFieldId,
        customFieldJSON: field.customFieldJSON,
        module: field.module,
        referenceId: field.referenceId || 0,
        value: field.value,
      }));
  }

  private mapCustomer(
    customer: Customer,
  ): InvoiceSnapshotTypes.InvoiceCustomer {
    return {
      address: customer.address,
      commercialNumber: customer.commercialRegisterNumber,
      customField: this.mapCustomFields(customer.CustomFieldsData || []),
      id: customer.id,
      name: customer.name,
      nationalId: customer.nationalId?.toString(),
      vatNumber: customer.vatNumber,
      phoneNumber: customer.mobileNumber,
    };
  }

  private mapLineItems(
    lineItems: CartTypes.SellApiLineItem[],
    isReturn: boolean,
  ): InvoiceSnapshotTypes.InvoiceLineItem[] {
    return lineItems.map((item): InvoiceSnapshotTypes.InvoiceLineItem => {
      const discount = item.discounts.find(
        (discount) =>
          discount.type !== CartEnums.DiscountTypeConstant.Promotion,
      );
      const promotion = item.discounts.find(
        (discount) =>
          discount.type === CartEnums.DiscountTypeConstant.Promotion,
      );
      const discountRate = discount?.rate || 0;
      return {
        batches:
          item.trackType === ProductEnums.TrackTypeConstant.Batch
            ? item.trackDetails.map((trackDetail, index) => ({
                id: trackDetail.id,
                quantity: this.negativeIfReturn(trackDetail.quantity, isReturn),
                sno: index + 1,
                trackNo: trackDetail.trackNo,
              }))
            : [],
        composites: item.composites.map((composite) => ({
          id: composite.id,
          name: composite.name,
          quantity: this.negativeIfReturn(
            item.quantity * composite.rate,
            isReturn,
          ),
        })),
        customFields: this.mapCustomFields(item.customFieldsData || []),
        discountAmount: this.negativeIfReturn(item.totalDiscount, isReturn),
        discountRate: discountRate,
        eCards: item.eCards,
        extras: item.extras.map((extra, index) => ({
          id: extra.id,
          name: extra.name,
          price: extra.priceTaxExclusive.base,
          quantity: this.negativeIfReturn(extra.quantity, isReturn),
          sku: extra.sku,
          sno: index + 1,
          subtotal: item.subtotal,
          total: item.total,
        })),
        id: item.variantId,
        name: item.name,
        packs: item.packs.map((pack) => ({
          id: pack.id,
          name: pack.name,
          quantity: this.negativeIfReturn(item.quantity * pack.rate, isReturn),
          rate: pack.rate,
        })),
        price: item.priceTaxExclusive.base,
        priceAfterPromoOrDiscount: this.negativeIfReturn(
          item.priceTaxExclusive.final,
          isReturn,
        ),
        promotion: promotion && {
          id: promotion.id || 0,
          amount: promotion.total,
          name: promotion.name,
        },
        quantity: this.negativeIfReturn(item.quantity, isReturn),
        serials:
          item.trackType === ProductEnums.TrackTypeConstant.Serial
            ? item.trackDetails.map((trackDetail, index) => ({
                id: trackDetail.id,
                sno: index + 1,
                trackNo: trackDetail.trackNo,
              }))
            : [],
        sku: item.sku,
        subtotal: this.negativeIfReturn(item.subtotal, isReturn),
        taxAmount: commonCalculationService.roundTo2Decimals(
          this.negativeIfReturn(item.totalTax, isReturn),
        ),
        taxRate: commonCalculationService.roundTo2Decimals(
          commonCalculationService.multiply(item.tax.rate, 100),
        ),
        total: this.negativeIfReturn(item.total, isReturn),
        totalTaxExc: this.negativeIfReturn(item.totalWithoutTax, isReturn),
        type: item.productType,
        weighted:
          item.unit &&
          ({
            id: 0,
            unit: item.unit,
          } as any),
        categoryIds: item.categoryIds,
      };
    });
  }

  private mapTaxes(
    taxSummary: CartTypes.TaxSummary,
    isReturn: boolean,
  ): InvoiceSnapshotTypes.InvoiceTax[] {
    return taxSummary
      .flatMap((tax) => {
        return tax.taxLines.map((taxLine) => {
          return {
            amount: commonCalculationService.roundTo2Decimals(
              this.negativeIfReturn(taxLine.total, isReturn),
            ),
            id: taxLine.id,
            name: taxLine.name,
            rate: commonCalculationService.roundTo2Decimals(
              commonCalculationService.multiply(taxLine.rate, 100),
            ),
          };
        });
      })
      .filter((taxLine) => taxLine.rate);
  }

  getMerchantAddress(stockLocation: StockLocationTypes.StockLocation): string {
    const name = stockLocation.name + ' -';
    const buildingNo = stockLocation.buildingNo || '';
    const streetName = stockLocation.streetName || '';
    const districtName = stockLocation.districtName || '';
    const city = stockLocation.city || '';
    const zipCode = stockLocation.zipCode || '';
    const country = stockLocation.country || '';
    const countryKey = stockLocation.countryKey || '';
    const additionalNumber = stockLocation.additionalNumber
      ? stockLocation.additionalNumber.toString()
      : '';

    const addressParts = [
      name,
      buildingNo,
      streetName,
      districtName,
      city,
      zipCode,
      additionalNumber,
      countryKey || country,
    ];

    return addressParts
      .filter((part) => part.trim().length > 0)
      .join(' ')
      .trim();
  }

  private negativeIfReturn(value: number, isReturn: boolean): number {
    return isReturn ? -value : value;
  }
}

export const invoiceSnapshotService = new InvoiceSnapshotService();
