import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { LabelWithChildren, PostLabels, PutLabels } from './types/labels-service-types';
import { PaginatedResponse } from '../../accounting/accounting.types';

const ApiPrefix = '/api';

@Injectable()
export class LabelsService {
  constructor(
    private http: HttpClient,
  ) {}

  async postLabels(labels: PostLabels) {
    const response = await firstValueFrom(this.http.post(
      `${ApiPrefix}/labels/label-group/${labels.labelGroup}`,
      labels.labels,
    ));
    return response;
  }

  async putLabels(labels: PutLabels) {
    const response = await firstValueFrom(this.http.put(
      `${ApiPrefix}/labels/label-group/${labels.labelGroup}/${labels.labels.id}`,
      labels.labels,
    ));
    return response;
  }

  async deleteLabels(ids: number[]): Promise<number[]> {
    return firstValueFrom(this.http.delete<number[]>(`${ApiPrefix}/labels`, { body: { ids } }));
  }

  // If its name is getCostCenters that there is no need for it to take labelGroup
  // either remove it or change it to more generic getLabels
  async getCostCenters(query) {
    const {
      offset, limit, labelGroup, search,
    } = query;
    const options = {
      params: new HttpParams()
        .set('search', search),
    };
    if (offset || limit) {
      options.params = options.params
        .set('offset', offset || 0)
        .set('limit', limit || 10);
    }
    return firstValueFrom(
      this.http.get<PaginatedResponse<LabelWithChildren>>(
        `${ApiPrefix}/labels/label-group/${labelGroup}`,
        options,
      ),
    );
  }
}
