export const TaxTypeConstant = {
  Inclusive: 'Inclusive',
  Exclusive: 'Exclusive',
} as const;

// export type TaxType =
//   (typeof TaxTypeConstant)[keyof typeof TaxTypeConstant];

export const enum TaxType {
  Inclusive = 'Inclusive',
  Exclusive = 'Exclusive',
}

export const enum PaymentMethodType {
  Cash = 'Cash',
  Card = 'Card',
  Other = 'Other',
  SoftPos = 'SoftPos',
}

export const enum FileStatus {
  Created = 'Created',
  Pending = 'Pending',
}

export const enum TaxCode {
  TobaccoCode = 'ST'
}
