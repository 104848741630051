// eslint-disable-next-line max-classes-per-file
import { ExpenseCategory } from './expenseCategory';
import { PaymentMethod } from '../../shared/model/PaymentMethod';
import { StockLocation } from '../../shared/model/StockLocation';
import { Register } from '../../internal-apps/pos/model/Register';
import { Tax } from '../../shared/model/Tax';
import { RecurringCycle } from '../constants';

export class Expense {
  public id?: number;

  public name?: string;

  public isTaxInclusive: boolean;

  public isRecurring: boolean;

  public referenceNumber?: string;

  public notes?: string;

  public paymentDate?: Date;

  public amount?: number;

  public taxAmount?: number;

  public paymentMethodId?: number;

  public PaymentMethod?: PaymentMethod;

  public locationNames?: string;

  public paymentMethodNames?: string;

  public considerPosCaseManagement?: boolean;

  public cashManagementLocationId: number;

  public cashManagementRegisterId?: number;

  public taxable?: boolean;

  public taxId?: number;

  public Tax?: Tax;

  public expenseCategoryId?: number;

  public ExpenseCategory?: ExpenseCategory;

  public StockLocation?: StockLocation;

  public Register?: Register;

  public ExpenseStockLocations?: any[];

  public ExpensePayments?: ExpensePayments[];

  public paymentMethod?: string;

  public attachments?: [];

  public updatedAt?: Date;

  public createdAt?: Date;

  public recurringStartDate: string;

  public recurringEndDate: string;

  public recurringStopDate?: string;

  public recurringCycle: RecurringCycle;

  constructor(expense?: unknown) {
    Object.assign(this, expense || {});
  }
}

export class ExpensePayments {
  amount: number;

  expenseId: number;

  id: number;

  paymentMethodId: number;

  taxAmount: number;
}
